import React from 'react';

function PopLoading() {
  return (
    <div>
      <div className='fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-10'>
        <div className=''>
          <div className='bg-white block p-3 rounded m-auto '>
            <h1 className='header2 text-center m-auto'>Tunggu Sebentar</h1>
            <h1 className='header4 text-center mb-5 m-auto'>
              Biasanya 20-30 Detik
            </h1>
            <img
              src={'./preload.svg'}
              className='m-auto w-20 mb-4'
              alt='loading'
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopLoading;
