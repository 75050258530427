import React from 'react';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';

function Dashsetting({
  handleSubmit,
  handleInputChange,
  inputData,
  handleDateChange,
}) {
  const cities = [
    { name: 'Dropship', code: 'ds' },
    { name: 'E-Commerce', code: 'ecom' },
  ];
  return (
    <div className='bg-white block rounddash mt-5 drop-shadow'>
      <h1 className='header1'>Setting Report</h1>
      <div className='grid grid-cols-4 gap-10 mt-1'>
        <div className='grid grid-cols-1'>
          <h1 className='header2'>Tanggal Mulai:</h1>
          <Calendar
            name='startdate'
            value={handleDateChange.startdate}
            onChange={handleDateChange}
            className='h-12'
            showButtonBar
            dateFormat='dd/mm/yy'
          />
        </div>
        <div className='grid grid-cols-1'>
          <h1 className='header2'>Tanggal Selesai:</h1>
          <Calendar
            name='enddate'
            value={handleDateChange.enddate}
            onChange={handleDateChange}
            className='h-12'
            showButtonBar
            dateFormat='dd/mm/yy'
          />
        </div>
        <div className='grid grid-cols-1'>
          <h1 className='header2 '>Mode:</h1>
          <Dropdown
            value={inputData.mode}
            onChange={handleInputChange}
            options={cities}
            optionLabel='name'
            name='mode'
            placeholder='Pilih Mode'
            className='h-12'
          />
        </div>
        <div className='grid grid-cols-1'>
          <Button onClick={handleSubmit} label='Lihat Report' />
        </div>
      </div>
    </div>
  );
}
export default Dashsetting;
