import React from 'react';
import Itemsdetail from './Itemsdetail';

function Dashresult({ lastupdate, datareport }) {
  const formatCurrency = (amount) => {
    return amount.toLocaleString('id-ID', {
      style: 'currency',
      currency: 'IDR',
    });
  };
  return (
    <div className='bg-white rounddash mt-5 drop-shadow'>
      <div className='flex'>
        <h1 className='header1'>{datareport.type}</h1>
        <h2 className='header2 ml-5 p-1 outline outline-1 rounded-sm m-auto outline-slate-300 '>
          {lastupdate}
        </h2>
      </div>
      <div className='mt-3'>
        <div>
          <h1 className='header3'>Summary :</h1>
        </div>
        <div className='bg-[#fff7fe] drop-shadow-sm  mt-2 inline-block p-3 outline outline-1 rounded-md m-auto mr-4 outline-slate-300 '>
          <div className='inline-block mr-5'>
            <h1 className='header2'>Total Terjual</h1>
            <h2 className='text-xl font-bold center text-stone-900'>
              {datareport.summary.sum_terjual} Pcs
            </h2>
          </div>
          <div className='inline-block '>
            <h1 className='header4'>
              | Sepatu : {datareport.summary.sum_sepatu} Pcs
            </h1>
            <h1 className='header4'>
              | Non Sepatu : {datareport.summary.sum_nonsepatu} Pcs
            </h1>
          </div>
        </div>
        <div className='bg-[#fff7fe] drop-shadow-sm  mt-2 inline-block p-3 outline outline-1 rounded-md m-auto mr-4 outline-slate-300'>
          <h1 className='header2'>Total Nominal</h1>
          <h2 className='text-xl font-bold center text-stone-900r'>
            {formatCurrency(datareport.summary.sum_nominal)}
          </h2>
        </div>
        <div className='bg-[#fff7fe] drop-shadow-sm mt-2 inline-block p-3 outline outline-1 rounded-md m-auto mr-4 outline-slate-300'>
          <h1 className='header2'>Total Chat</h1>
          <h2 className='text-xl font-bold center text-stone-900'>
            {datareport.summary.sum_chat} Chat
          </h2>
        </div>
      </div>
      <div className='mt-7'>
        <div>
          <h1 className='header3'>Detail Report :</h1>
        </div>
        <div className='grid grid-cols-4 gap-5 mt-2'>
          {datareport.detail.map((item) => (
            <Itemsdetail key={item.de_device} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Dashresult;
