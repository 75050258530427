import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useState } from 'react';

function Poplogin({ handlepassword, submitpassword }) {
  const [value, setValue] = useState('');
  return (
    <div>
      <div className='fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-10'>
        <div className=''>
          <div className='bg-white block p-3 rounded m-auto '>
            <h1 className='header1 text-center m-auto mb-3 mt-3'>
              Masukan kode akses
            </h1>
            <div className='block'>
              <InputText
                value={handlepassword.pass}
                onChange={handlepassword}
                name='pass'
                className='h-12'
              />
            </div>
            <div className='mt-2 m-auto'>
              <Button
                className='m-auto'
                label='Lanjut'
                size='small'
                onClick={submitpassword}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Poplogin;
