import './App.css';
import Dashhead from './component/Dashhead';
import Dashsetting from './component/Dashsetting';
import Dashresult from './component/Dashresult';
import { useState, useEffect } from 'react';
import axios from 'axios';
import PopLoading from './component/PopLoading';
import Poplogin from './component/Poplogin';

function App() {
  const [lastupdate, setlastupdate] = useState('-');
  const [datareport, setdatareport] = useState('-');
  const [showreport, setshowreport] = useState(false);
  const [showloading, setshowloading] = useState(false);
  const [showpoplogin, setshowpoplogin] = useState(true);
  const [realpassword, setrealpassword] = useState('-');
  const [actualpass, setactualpass] = useState('-');
  const [inputData, setInputData] = useState({
    startdate: '',
    enddate: '',
    mode: '',
  });

  const fetchtanggal = async () => {
    try {
      const response = await axios.get('https://api2.sidehustle.id/tanggal');
      setlastupdate(response.data);
    } catch (error) {
      console.log();
    }
  };
  const fetchpw = async () => {
    try {
      const response = await axios.get('https://api2.sidehustle.id/getpw');
      setrealpassword(response.data);
    } catch (error) {
      console.log();
    }
  };
  const handleDateChange = (event) => {
    const { name, value } = event.target;
    // Gunakan toLocaleDateString untuk mengubah format tanggal menjadi "dd/mm/yyyy"
    const formattedDate = new Date(value).toLocaleDateString('en-CA', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    setInputData((prevInputData) => ({
      ...prevInputData,
      [name]: formattedDate, // Set state dengan tanggal baru dalam format "dd/mm/yyyy"
    }));
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    setInputData((prevInputData) => ({
      ...prevInputData,
      [name]: newValue, // Gunakan name (nama input) sebagai kunci untuk dictionary
    }));
  };

  const handlepassword = (event) => {
    setactualpass(event.target.value);
  };

  const submitpassword = (event) => {
    if (actualpass == realpassword) {
      setshowpoplogin(false);
    } else {
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Lakukan apa yang perlu dilakukan dengan data inputData
    if (
      (inputData.startdate != '') &
      (inputData.enddate != '') &
      (inputData.mode != '')
    ) {
      axios
        .post('https://api2.sidehustle.id/getdata', inputData)
        .then((getResponse) => {
          console.log(getResponse.data);
          setdatareport(getResponse.data);
          setshowreport(true);
        });
    }
  };

  const handleallupdate = () => {
    setshowloading(true);
    axios.get('https://api2.sidehustle.id/updatedata').then((getResponse) => {
      fetchtanggal();
      setshowloading(false);
    });
  };

  useEffect(() => {
    fetchpw();
    fetchtanggal();
  }, []);

  return (
    <div>
      <div className='p-6 w-[68rem] m-auto'>
        {showpoplogin ? (
          <Poplogin
            handlepassword={handlepassword}
            submitpassword={submitpassword}
          />
        ) : null}

        {showloading ? <PopLoading /> : null}

        <Dashhead lastupdate={lastupdate} handleallupdate={handleallupdate} />
        <Dashsetting
          handleInputChange={handleInputChange}
          inputData={inputData}
          handleSubmit={handleSubmit}
          handleDateChange={handleDateChange}
        />
        {showreport ? (
          <Dashresult lastupdate={lastupdate} datareport={datareport} />
        ) : null}
      </div>
    </div>
  );
}

export default App;
