import React from 'react';

function Itemsdetail({ item }) {
  return (
    <div className=''>
      <div className='p-3 outline outline-1 rounded-md  drop-shadow-sm outline-slate-200 bg-[#f7f7ff]'>
        <h1 className='header2'>{item.de_device}</h1>
        <div className='grid grid-cols-2 gap-5 mt-1'>
          <h2 className='header4 font-semibold'>Closing</h2>
          <h2 className='header4'>: {item.de_closing} Pcs</h2>
        </div>
        <div className='grid grid-cols-2 gap-5 mt-1'>
          <h2 className='header4'>Chat</h2>
          <h2 className='header4'>: {item.de_chat} Chat</h2>
        </div>
      </div>
    </div>
  );
}

export default Itemsdetail;
