import React from 'react';
import { Button } from 'primereact/button';

function Dashhead({ lastupdate, handleallupdate }) {
  return (
    <div>
      <div className='bg-white inline-block p-5 rounded-lg drop-shadow'>
        <h1 className='header1'>Pembaharuan Terakhir</h1>
        <div className='flex items-center '>
          <h2 className='header2 mr-2'>{lastupdate}</h2>
          <Button
            className='m-100'
            icon='pi pi-sync'
            text
            size='small'
            onClick={handleallupdate}
          />
        </div>
      </div>
    </div>
  );
}

export default Dashhead;
